<template>
  <div class='mobile-signup-main-wrapper'>
    <a-form>
      <a-row style="display: block;">
        <a-col>
          <div class='mobile-signup-title' style='margin-top: 100px'>
            Signup now
          </div>
          <div class='mobile-signup-title-description'>
            Or track your existing application
          </div>
          <div class='mobile-signup-sub-title-description'>
            Open a trading and demat account online and start investing
          </div>
          <div class='mobile-signup-input-btn-wrapper'>
            <a-form-item v-bind='validateInfos.mobile'>
              <a-input
                v-model:value='modelRef.mobile'
                placeholder='Your 10 digit mobile number'
                class='mobile-number-input'
                :maxlength=10
                @keypress='onlyNumber'
                :disabled='disable'
              >
                <template #prefix>
                  <div>+91</div>
                </template>
              </a-input>
            </a-form-item>
          </div>
          <div class='mobile-signup-btn-wrapper'>
            <a-button type='primary' @click='onClickSignUp' :loading="loading">Signup now</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>

</template>
<script>
import { useRouter } from 'vue-router';
import {
  onMounted,
  reactive,
  // computed,
  ref,
} from 'vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';
import getLogRocket from '../../../services/logrocket';

const onlyNumber = ($event) => {
  const keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    // 46 is dot
    $event.preventDefault();
  }
};
const { useForm } = Form;

export default {
  setup() {
    const store = useStore();
    // const route = useRoute();
    const disable = ref(false);
    const loading = ref(false);
    const logrocket = getLogRocket();

    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
    });
    const router = useRouter();
    const modelRef = reactive({
      mobile: '',
    });

    const rulesRef = reactive({
      mobile: [
        {
          required: true,
          message: 'Your 10 digit mobile number',
          // min: 10,
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );

    const onClickSignUp = () => {
      validate().then(() => {
        loading.value = true;
        disable.value = true;
        services
          .mobileSignUp(modelRef)
          .then(() => {
            loading.value = false;
            store.dispatch('onboardingStore/updateMobileNumber', modelRef.mobile);
            store.dispatch('onboardingStore/updateClientLogin', true);
            router.push('/onboarding/mobile/otp');
            logrocket.setUser(modelRef.mobile, {
              mobile: modelRef.mobile,
            });
          })
          .catch((error) => {
            loading.value = false;
            disable.value = false;
            const systemError = Operations.axiosErrorHandler(error);
            const message = systemError.status;
            const description = systemError.message
              ? systemError.message
              : '';
            Operations.notificationError(message, description);
          });
      });
    };

    return {
      onClickSignUp,
      validateInfos,
      resetFields,
      modelRef,
      onlyNumber,
      disable,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/mobileSignUp.scss';
</style>
